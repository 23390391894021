import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import { ReactComponent as Structures } from "assets/icons/structures.svg";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import { ReactComponent as DataTemp } from "assets/icons/data-temp.svg";
import { ReactComponent as DataElec } from "assets/icons/data-elec.svg";
import { ReactComponent as Simulation } from "assets/icons/simulation.svg";
import { FormattedMessage, useIntl } from "react-intl";
import CommonMessages from "components/common/CommonMessages";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationCreateMessages from "pages/SimulationCreate/SimulationCreateMessages";
import SimulationDropItemMessages from "components/SimulationDropItem/SimulationDropItemMessages";
import DatasetRenderList from "components/SimulationDropItem/DatasetRenderList";
import SteeringAlgorithmsRenderList from "components/SimulationDropItem/SteeringAlgorithmsRenderList";
import CropsRenderList from "components/SimulationDropItem/CropsRenderList";
import SoilInformationsRenderList from "components/WaterBalanceDropItem/SoilInformationsRenderList";
import StructuresRenderList from "components/SimulationDropItem/StructuresRenderList";
import Line from "components/WaterBalanceDropItem/Line";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import PVModulePropertiesRenderList from "components/SimulationDropItem/PVModulePropertiesRenderList";
import StructuresCreateMessages from "pages/StructuresCreate/StructuresCreateMessages";
import GeneralRenderList from "components/SimulationDropItem/GeneralRenderList";
import GrasslandYieldPropertiesRenderList from "components/SimulationDropItem/GrasslandProperties";

const SimulationConfigContent = ({ result }: any) => {
  const [isStructureOpen, setIsStructureOpen] = useState(false);
  const [isSoilInformationOpen, setIsSoilInformation] = useState(false);
  const [isCropOpen, setIsCropOpen] = useState(false);
  const [isSteeringAlgorithmOpen, setIsSteeringAlgorithmOpen] = useState(false);
  const [isWeatherDatasetOpen, setIsWeatherDatasetOpen] = useState(false);
  const [isProductionDatasetOpen, setIsProductionDatasetOpen] = useState(false);
  const [isETPDatasetOpen, setIsETPDatasetOpen] = useState(false);
  const [isUniversalDatasetOpen, setIsUniversalDatasetOpen] = useState(false);

  const intl = useIntl();

  //   return (
  //     <div className={styled.wrapper}>
  //       <div className={styled.essenceContainer}>
  //         <Line
  //           title={intl.formatMessage(
  //             WaterBalanceResultBodyMessages.simulationFrequency
  //           )}
  //           value={result.simulation.frequency}
  //         />
  //       </div>
  //       <div className={styled.essenceContainer}>
  //         <Line
  //           title={intl.formatMessage(WaterBalanceResultBodyMessages.resolution)}
  //           value={result.simulation.resolution}
  //         />
  //       </div>

  const simu_obj = useMemo(() => {
    return {
      ["General"]: result.simulation,
      ["Structure"]: result.simulation.structure,
      ["Soil Information"]: result.simulation.water_balance,
      ["Crop"]: result.simulation.crop_for_water_balance,
      ["Steering Algorithm"]:
        result.simulation.steering_algorithm_for_water_balance,
      ["Weather Dataset"]: result.simulation.universal_dataset
        ? null
        : result.simulation.weather_dataset,
      // ["Production Dataset"]: result.simulation.production_dataset
      //   ? result.simulation.production_dataset.name == "__Dataset__"
      //     ? null
      //     : result.simulation.production_dataset
      //   : null,
      ["ETP Dataset"]: result.simulation.universal_dataset
        ? null
        : result.simulation.ETP_dataset,
      ["Climatic Dataset"]: result.simulation.universal_dataset
        ? result.simulation.universal_dataset
        : null,
      ["Pv Module Properties"]: result.simulation.structure,
      ["Grassland Yield Properties"]: result.simulation.grassland_primary_yield
        ? result.simulation.grassland_primary_yield
        : null,
    };
  }, [result]);
  const [selectedObject, setSelectedObject] = useState<keyof typeof simu_obj>(
    Object.keys(simu_obj)[0] as keyof typeof simu_obj
  );

  const InputDisplay = () => {
    if (selectedObject == "Structure")
      return (
        <>
          <div className={styled.title}>
            <Structures />
            {simu_obj.Structure.name}
          </div>
          <StructuresRenderList selected={simu_obj.Structure} />
        </>
      );
    if (selectedObject == "Soil Information")
      return (
        <>
          <div className={styled.title}>
            <Icon
              name={ICON_NAMES_ENUM.terrain_icon}
              className={styled.file__icon}
            />
            {simu_obj["Soil Information"].name}
          </div>
          <SoilInformationsRenderList selected={simu_obj["Soil Information"]} />
        </>
      );
    if (selectedObject == "Crop")
      return (
        <>
          <div className={styled.title}>
            <Crops />
            {simu_obj.Crop.name}
          </div>
          <CropsRenderList selected={simu_obj.Crop} />
        </>
      );
    if (selectedObject == "Steering Algorithm")
      return (
        <>
          <div className={styled.title}>
            <DataTemp />
            {simu_obj["Steering Algorithm"].name}
          </div>
          <SteeringAlgorithmsRenderList
            selected={simu_obj["Steering Algorithm"]}
          />
        </>
      );
    if (
      selectedObject == "Weather Dataset" &&
      !result.simulation.universal_dataset
    )
      return (
        <>
          <div className={styled.title}>
            <DataTemp />
            {simu_obj["Weather Dataset"].name}
          </div>
          <DatasetRenderList selected={simu_obj["Weather Dataset"]} />
        </>
      );
    // if (selectedObject == "Production Dataset")
    // 	return <>
    // 		<div className={styled.title}><DataElec />
    // 			{simu_obj['Production Dataset'].name}
    // 		</div>
    // 		<DatasetRenderList selected={simu_obj['Production Dataset']} />
    // 	</>
    if (selectedObject == "ETP Dataset" && !result.simulation.universal_dataset)
      return (
        <>
          <div className={styled.title}>
            <DataTemp />
            {simu_obj["ETP Dataset"].name}
          </div>
          <DatasetRenderList selected={simu_obj["ETP Dataset"]} />
        </>
      );
    if (selectedObject == "Climatic Dataset")
      return (
        <>
          <div className={styled.title}>
            <DataTemp />
            {simu_obj["Climatic Dataset"].name}
          </div>
          <DatasetRenderList selected={simu_obj["Climatic Dataset"]} />
        </>
      );
    if (selectedObject == "Pv Module Properties")
      return (
        <>
          <div className={styled.title}>
            <Icon name={ICON_NAMES_ENUM.bolt} className={styled.file__icon} />
            <FormattedMessage {...StructuresCreateMessages.PvModule} />
          </div>
          <PVModulePropertiesRenderList
            selected={simu_obj["Pv Module Properties"]}
          />
        </>
      );
    if (selectedObject == "Grassland Yield Properties")
      return (
        <>
          <div className={styled.title}>
            <Icon name={ICON_NAMES_ENUM.bolt} className={styled.file__icon} />
            <FormattedMessage {...StructuresCreateMessages.GrasslandYield} />
          </div>
          <GrasslandYieldPropertiesRenderList
            selected={simu_obj["Grassland Yield Properties"]}
          />
        </>
      );
    if (selectedObject == "General")
      return (
        <>
          <div className={styled.title}>
            <Simulation />
            General
          </div>
          <GeneralRenderList selected={simu_obj.General} />
        </>
      );
    return <></>;
  };

  return (
    <div className={styled.configContainer}>
      <div className={styled.inputContainer}>
        {Object.keys(simu_obj)
          .filter((key) => simu_obj[key as keyof typeof simu_obj] != null)
          .map((key: any) => (
            <div
              className={clsx(styled.inputItem, {
                [styled.selected]: selectedObject == key,
              })}
              onClick={() => setSelectedObject(key)}
            >
              {key}
            </div>
          ))}
      </div>
      <div className={styled.displayContainer}>
        <InputDisplay />
      </div>
    </div>
  );
};

export default SimulationConfigContent;
