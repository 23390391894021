import Select from "components/common/select/Select";
import AvailableWaterStockContent from "./AvailableWaterStockContent";
import ETPContent from "./ETPContent";
import WaterConsumptionContent from "./WaterConsumptionContent";
import WaterDeficitContent from "./WaterDeficitContent";
import styled from "./styled.module.scss";
import { useState } from "react";

const WaterbalanceContent = ({ result }: any) => {
  const chartTypes = [
    {
      label: "Water Balance",
      value: "WATER_BALANCE",
    },
    // {
    //   label: "Water Consumption",
    //   value: "WATER_CONSUMPTION",
    // },
    {
      label: "Water Deficit",
      value: "WATER_DEFICIT",
    },
    {
      label: "ETM",
      value: "ETM",
    },
  ];
  const chartUnites = [
    {
      label: "%",
      value: "FRACTION",
    },
    {
      label: "mm",
      value: "MM",
    },
  ];
  const chartUnitesWaterDeficit = [
    {
      label: "mm",
      value: "MM",
    },
  ];
  const ETMTableTypeOptions = [
    { label: "Per Month", value: "MONTH" },
    { label: "Per Stage", value: "STAGE" },
  ];
  const [selectedChartType, setSelectedChartType] = useState<
    "WATER_BALANCE" | "WATER_DEFICIT" | "ETM"
  >("WATER_BALANCE");
  const [selectedChartUnite, setSelectedChartUnite] = useState<
    "FRACTION" | "MM"
  >("FRACTION");
  const [selectedETMTableTypeOptions, setSelectedETMTableTypeOptions] =
    useState<"STAGE" | "MONTH">("MONTH");

  const Content = () => {
    if (selectedChartType == "WATER_BALANCE")
      return (
        <AvailableWaterStockContent
          result={result}
          unite={selectedChartUnite}
        />
      );
    // else if (selectedChartType == "WATER_CONSUMPTION")
    //   return (
    //     <WaterConsumptionContent result={result} unite={selectedChartUnite} />
    //   );
    else if (selectedChartType == "WATER_DEFICIT")
      return <WaterDeficitContent result={result} unite={selectedChartUnite} />;
    else if (selectedChartType == "ETM")
      return (
        <ETPContent
          result={result}
          unite={selectedChartUnite}
          period={selectedETMTableTypeOptions}
        />
      );
    else return <></>;
  };

  return (
    <div className={styled.list}>
      <div className={styled.chartContainer}>
        <div className={styled.optionContainer}>
          <div className={styled.doubleUniteSelector}>
            <div
              className={styled.doubleUniteSelectorItem}
              style={{
                width: "300px",
              }}
            >
              <Select
                label="Chart Type"
                labelPosition="left"
                options={chartTypes}
                value={chartTypes.find(
                  (item) => item.value == selectedChartType
                )}
                onChange={(e: any) => {
                  setSelectedChartType(e.value);
                  if (e.value == "WATER_DEFICIT") setSelectedChartUnite("MM");
                }}
                isSearchable={false}
                menuHeight={400}
              />
            </div>
            {selectedChartType !== "WATER_DEFICIT" && (
              <div className={styled.doubleUniteSelectorItem}>
                <Select
                  label="Chart Unit"
                  labelPosition="left"
                  options={chartUnites}
                  value={chartUnites.find(
                    (item) => item.value == selectedChartUnite
                  )}
                  onChange={(e: any) => {
                    setSelectedChartUnite(e.value);
                  }}
                  isSearchable={false}
                  menuHeight={400}
                />
              </div>
            )}
            {selectedChartType === "WATER_DEFICIT" && (
              <div className={styled.doubleUniteSelectorItem}>
                <Select
                  label="Chart Unit"
                  labelPosition="left"
                  options={chartUnitesWaterDeficit}
                  value={chartUnitesWaterDeficit.find(
                    (item) => item.value == selectedChartUnite
                  )}
                  onChange={(e: any) => {
                    setSelectedChartUnite(e.value);
                  }}
                  isSearchable={false}
                  menuHeight={400}
                />
              </div>
            )}
            {selectedChartType === "ETM" && (
              <div className={styled.doubleUniteSelectorItem}>
                <Select
                  label="Period"
                  labelPosition="left"
                  options={ETMTableTypeOptions}
                  value={ETMTableTypeOptions.find(
                    (item) => item.value == selectedETMTableTypeOptions
                  )}
                  onChange={(e: any) => {
                    setSelectedETMTableTypeOptions(e.value);
                  }}
                  isSearchable={false}
                  menuHeight={400}
                />
              </div>
            )}
          </div>
        </div>
        <Content />
      </div>
      {/* <div className={styled.row}>
			<div style={{ width: "calc(50% - 1rem)" }}>
				<AvailableWaterStockContent result={result} />
			</div>
			<div className={styled.verticalLimiter} />
			<div style={{ width: "calc(50% - 1rem)" }}>
				<WaterConsumptionContent result={result} />
			</div>
		</div>
		<div className={styled.limiter} />
		<div className={styled.row}>
			<div style={{ width: "calc(50% - 1rem)" }}>
				<WaterDeficitContent result={result} />
			</div>
			<div className={styled.verticalLimiter} />
			<div style={{ width: "calc(50% - 1rem)" }}>
				<ETPContent result={result} />
			</div>
		</div> */}
    </div>
  );
};

export default WaterbalanceContent;
