import React, { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import structuresCreateMessages from "pages/StructuresCreate/StructuresCreateMessages";
import DottedLine from "components/DottedLine/DottedLine";
import Label from "components/common/label/Label";
import Input from "components/common/input/Input";
import InputRange from "components/common/inputRange/InputRange";
import Switch from "components/common/switch/Switch";
import CheckBox from "components/common/checkBox/CheckBox";
import clsx from "clsx";
import DatePicker from "components/common/datepicker/DatePicker";
import Button from "components/common/button/Button";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Plus } from "assets/icons/plus-circle.svg";
import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import AddPointModal, {
  IAddPointForm,
} from "components/AddPointModal/AddPointModal";
import { IStructureFormik } from "pages/StructuresCreate/StructuresCreate";
import { IFormik } from "components/common/FormikType";
import moment, { Moment } from "moment";
import { Structure_3D } from "components/Structure_3D/Structure_3D";
import { useParams } from "react-router-dom";
import { getProjectById } from "store/projects/selectors";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import { lowerFirst } from "lodash";
import GapBetweenTablesSchema from "assets/GapBetweenTablesSchema.png";
import PitchBetweenTablesSchema from "assets/PitchBetweenTablesSchema.png";
import { SingleDatePicker } from "react-dates";
import Select from "components/common/select/Select";

interface IStructuresBody {
  formik: IFormik<IStructureFormik>;
  image: string;
  onImageLoading: boolean;
  fromTemplate?: boolean;
  onDraw: () => void;
  setInitialValue: (str: "fixed" | "tracker") => void;
}
const monthOptions = moment.months().map((label, value) => ({ label, value }));

const StructuresBody = ({
  formik,
  image,
  onDraw,
  onImageLoading,
  setInitialValue,
  fromTemplate = false,
}: IStructuresBody) => {
  const [datePickerFocus, setDatePickerFocus] = useState(false);

  const intl = useIntl();
  const isTracker = useMemo(() => {
    return formik.values.structure_type !== "fixed";
  }, [formik.values.structure_type]);
  const [IsOpenModal, setIsOpenModal] = useState(false);
  const [isDate, setIsDate] = useState(true);
  const [date, setDate] = useState<Moment | null>(moment(formik.values.day));
  const [editPoint, setEditPoint] = useState<number | null>(null);
  const { client_id, project_id } = useParams();
  const currentProject = useSelector(getProjectById(project_id));
  const [defaultPositionCanvas, setDefaultPositionCanvas] = useState(false);

  const getInputProps = (key: keyof IStructureFormik) => {
    return {
      name: key,
      value: formik.values[key] as string | number,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      errorMessage: formik.touched[key] ? (formik.errors[key] as string) : "",
      step: 0.01,
    };
  };
  const displayUTC = (utc: number) => {
    if (utc === 0) return "UTC0";
    return utc
      ? `UTC${utc < 0 ? "-" : "+"}${
          utc && utc % 3600 == 0
            ? Math.abs(utc / 3600)
            : `${Math.floor(Math.abs(utc / 3600))}:${(utc % 3600) / 60}`
        }`
      : "";
  };

  const onChangeRange = (key: keyof IStructureFormik) => (values: number[]) => {
    formik.setFieldValue(key, values[0]);
  };

  const onChangeCheckBox =
    (key: keyof IStructureFormik) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(key, e.target.checked);
    };

  const onChangeDate = (val: Moment | null) => {
    formik.setFieldValue("day", val?.format("yyyy-MM-DD") ?? null);
    setDate(val);
  };

  const onHideModal = () => {
    setIsOpenModal(false);
    setTimeout(() => setEditPoint(null), 200);
  };

  const onShowModal = () => {
    setIsOpenModal(true);
  };

  const onEditPoint = (index: number) => () => {
    setEditPoint(index);
    setIsOpenModal(true);
  };

  const onSavePoint = (v: IAddPointForm) => {
    const temp = [...formik.values.points];

    if (editPoint !== null) {
      temp[editPoint] = v;
    } else {
      temp.push(v);
    }

    formik.setFieldValue("points", temp);
    onHideModal();
  };

  const onPointDelete = (index: number) => () => {
    const points = [...formik.values.points];
    points.splice(index, 1);
    formik.setFieldValue("points", points);
  };

  const onChangeStructureType = (v: boolean) => {
    // setIsTracker(v);
    formik.setFieldValue("structure_type", v ? "tracker" : "fixed");
    setInitialValue(v ? "tracker" : "fixed");
  };

  const onChangeSolarPosition = (v: boolean) => {
    setIsDate(v);
    formik.setFieldValue("solar_position", v ? "calculated" : "simple");
  };

  useEffect(() => {
    const angle =
      formik.values.structure_type == "fixed"
        ? formik.values.static_angle
        : formik.values.tracking_max_angle;
    const panel_size = formik.values.panel_width / 2;
    const val1 =
      Math.round(
        (formik.values.panel_height +
          panel_size * Math.sin((angle * Math.PI) / 180)) *
          100
      ) / 100;
    const val2 =
      Math.round(
        (formik.values.panel_height -
          panel_size * Math.sin((angle * Math.PI) / 180)) *
          100
      ) / 100;
    const lower = val1 < val2 ? val1 : val2;
    const upper = val1 > val2 ? val1 : val2;

    formik.setValues({
      ...formik.values,
      panel_lower: lower,
      panel_upper: upper,
    });
  }, [
    formik.values.panel_width,
    formik.values.static_angle,
    formik.values.tracking_max_angle,
  ]);

  const getGCR = () => {
    if (formik.values.structure_type === "tracker")
      return (
        Math.round(
          (formik.values.panel_width / formik.values.panels_gap_x) * 10000
        ) / 100
      );
    else
      return (
        Math.round(
          ((formik.values.panel_width *
            Math.cos(formik.values.static_angle * (Math.PI / 180.0))) /
            formik.values.panels_gap_y) *
            10000
        ) / 100
      );
  };

  useEffect(() => {
    if (formik.values.structure_type == "tracker") {
      const angle = formik.values.tracking_max_angle;
      const panel_size = formik.values.panel_width / 2;
      const val1 =
        Math.round(
          (formik.values.panel_height +
            panel_size * Math.sin((angle * Math.PI) / 180)) *
            100
        ) / 100;
      const val2 =
        Math.round(
          (formik.values.panel_height -
            panel_size * Math.sin((angle * Math.PI) / 180)) *
            100
        ) / 100;
      const lower = val1 < val2 ? val1 : val2;
      const upper = val1 > val2 ? val1 : val2;
      formik.setValues({
        ...formik.values,
        panel_lower: lower,
        panel_upper: upper,
      });
    }
  }, [formik.values.panel_height]);

  const getLowerUpperInputProps = (type: "lower" | "upper") => {
    const angle =
      formik.values.structure_type == "fixed"
        ? formik.values.static_angle
        : formik.values.tracking_max_angle;
    const panel_size = formik.values.panel_width;

    if (type == "lower") {
      if (formik.values.structure_type == "fixed")
        return {
          value: formik.values.panel_lower,
          onChange: (event: any) => {
            const value = Number(event.target.value);
            const size = panel_size / 2;
            const sin = Math.sin((angle * Math.PI) / 180);
            const val1 = value + size * sin;
            const val2 = value - size * sin;
            const height = val1 >= val2 ? val1 : val2;
            const upper =
              Math.round(
                (height + size * Math.tan((angle * Math.PI) / 180)) * 100
              ) / 100;
            formik.setValues({
              ...formik.values,
              panel_height: height,
              panel_lower: value,
              panel_upper: upper,
            });
          },
        };
      else
        return {
          value: formik.values.panel_lower,
          disabled: true,
        };
    } else
      return {
        value: formik.values.panel_upper,
        disabled: true,
      };
  };

  return (
    <div className={styled.grid}>
      <div className={styled.column}>
        <h2 className={styled.sectionTitle}>
          <FormattedMessage {...structuresCreateMessages.tablePosition} />
        </h2>

        {formik.values.structure_type == "fixed" && (
          <div className={styled.panelHeightContainer}>
            <Input
              className={styled.panelHeightSelector}
              label={intl.formatMessage(
                structuresCreateMessages.lowerTableTipHeight
              )}
              type="number"
              {...getInputProps("panel_height")}
              {...getLowerUpperInputProps("lower")}
            />
            <Input
              className={styled.panelHeightSelector}
              label={intl.formatMessage(
                structuresCreateMessages.upperTableTipHeight
              )}
              type="number"
              {...getInputProps("panel_height")}
              {...getLowerUpperInputProps("upper")}
            />
          </div>
        )}
        {formik.values.structure_type == "tracker" && (
          <div className={styled.panelHeightContainer}>
            <Input
              className={styled.panelHeightSelector}
              label={intl.formatMessage(
                structuresCreateMessages.lowerTableTipHeight
              )}
              type="number"
              {...getInputProps("panel_lower")}
              {...getLowerUpperInputProps("lower")}
            />
            <Input
              label={intl.formatMessage(
                structuresCreateMessages.rotationAxisHeight
              )}
              type="number"
              {...getInputProps("panel_height")}
            />
            <Input
              className={styled.panelHeightSelector}
              label={intl.formatMessage(
                structuresCreateMessages.upperTableTipHeight
              )}
              type="number"
              {...getInputProps("panel_height")}
              {...getLowerUpperInputProps("upper")}
            />
          </div>
        )}
        <InputRange
          label={intl.formatMessage(structuresCreateMessages.tableOpacity)}
          values={[formik.values.panel_opacity]}
          min={0}
          max={1}
          step={0.01}
          onChange={onChangeRange("panel_opacity")}
        />

        <DottedLine
          item1={
            <Label>
              <FormattedMessage {...structuresCreateMessages.tableWidth} />
            </Label>
          }
          item2={
            <Input
              type="number"
              {...getInputProps("panel_width")}
              onInput={() => {}}
              onKeyDown={() => {}}
            />
          }
        />
        <DottedLine
          item1={
            <Label>
              <FormattedMessage {...structuresCreateMessages.tableLength} />
            </Label>
          }
          item2={<Input type="number" {...getInputProps("panel_length")} />}
        />

        <DottedLine
          item1={
            <Label>
              <Tooltip
                title={
                  <img
                    className={styled.tooltipImage}
                    src={GapBetweenTablesSchema}
                    alt="Gap Between Tables Schema"
                  />
                }
              >
                <div className={styled.flex}>
                  <FormattedMessage
                    {...structuresCreateMessages.gapBetweenPanels}
                  />
                  <div className={styled["help-icon"]}>
                    <Icon
                      name={ICON_NAMES_ENUM.help_circle}
                      className={styled.file__icon}
                    />
                  </div>
                </div>
              </Tooltip>
            </Label>
          }
          item2={
            <Input
              type="number"
              {...getInputProps(
                formik.values.structure_type == "fixed"
                  ? "panels_gap_x"
                  : "panels_gap_y"
              )}
            />
          }
        />
        <DottedLine
          item1={
            <Label>
              <Tooltip
                title={
                  <img
                    className={styled.tooltipImage}
                    src={PitchBetweenTablesSchema}
                    alt="Pitch Between Tables Schema"
                  />
                }
              >
                <div className={styled.flex}>
                  <FormattedMessage
                    {...structuresCreateMessages.pitchBetweenPanels}
                  />
                  <div className={styled["help-icon"]}>
                    <Icon
                      name={ICON_NAMES_ENUM.help_circle}
                      className={styled.file__icon}
                    />
                  </div>
                </div>
              </Tooltip>
            </Label>
          }
          item2={
            <Input
              type="number"
              {...getInputProps(
                formik.values.structure_type == "fixed"
                  ? "panels_gap_y"
                  : "panels_gap_x"
              )}
            />
          }
        />

        {/* <DottedLine
          item1={
            <Label>
              <FormattedMessage {...structuresCreateMessages.numberOfPanels} />
            </Label>
          }
          item2={
            <div className={styled.row}>
              <Input
                beforeIcon="X"
                type="number"
                integerNumber
                {...getInputProps("panels_number_x")}
                step={1}
              />

              <Input
                beforeIcon="Y"
                type="number"
                integerNumber
                {...getInputProps("panels_number_y")}
                step={1}
              />
            </div>
          }
        /> */}

        {/* <DottedLine
          item1={
            <Label>
              <FormattedMessage {...structuresCreateMessages.initialOffset} />
            </Label>
          }
          item2={
            <div className={styled.row}>
              <Input
                beforeIcon="X"
                type="number"
                {...getInputProps("initial_offset_x")}
                step={0.1}
              />

              <Input
                beforeIcon="Y"
                type="number"
                {...getInputProps("initial_offset_y")}
                step={0.1}
              />
            </div>
          }
        /> */}

        {/* <DottedLine
          item1={
            <Label>
              <FormattedMessage {...structuresCreateMessages.fieldSize} />
            </Label>
          }
          item2={
            <div className={styled.row}>
              <Input
                beforeIcon="X"
                type="number"
                integerNumber
                {...getInputProps("field_size_x")}
                disabled
              />

              <Input
                beforeIcon="Y"
                type="number"
                integerNumber
                {...getInputProps("field_size_y")}
                disabled
              />
            </div>
          }
        /> */}

        <InputRange
          label={intl.formatMessage(structuresCreateMessages.azimuth)}
          values={[formik.values.azimuth]}
          min={-90}
          max={90}
          step={1}
          onChange={onChangeRange("azimuth")}
        />
        <h2 className={styled.sectionTitle}>
          <FormattedMessage {...structuresCreateMessages.PvModule} />
        </h2>

        {/* <InputRange
          label={intl.formatMessage(structuresCreateMessages.albedo)}
          values={[formik.values.albedo]}
          min={0}
          max={1}
          step={0.01}
          onChange={onChangeRange("albedo")}
        />
         */}
        <DottedLine
          item1={
            <Label>
              <FormattedMessage
                {...structuresCreateMessages.moduleEfficiency}
              />
            </Label>
          }
          item2={
            <Input
              type="number"
              {...getInputProps("module_efficiency_STC")}
              onInput={() => {}}
              onKeyDown={() => {}}
            />
          }
        />

        <DottedLine
          item1={
            <Label>
              <FormattedMessage
                {...structuresCreateMessages.temperatureCoefficientsPower}
              />
            </Label>
          }
          item2={
            <Input
              type="number"
              {...getInputProps("temperature_cefficients_power")}
              onInput={() => {}}
              onKeyDown={() => {}}
            />
          }
        />

        <DottedLine
          item1={
            <Label>
              <Tooltip
                title={
                  "If the bifacial factor is equal to 0, the panels will be monofacial."
                }
              >
                <div className={styled.flex}>
                  <FormattedMessage
                    {...structuresCreateMessages.bifacialFactor}
                  />
                  <div className={styled["help-icon"]}>
                    <Icon
                      name={ICON_NAMES_ENUM.help_circle}
                      className={styled.file__icon}
                    />
                  </div>
                </div>
              </Tooltip>
            </Label>
          }
          item2={
            <Input
              type="number"
              {...getInputProps("bifacial_factor")}
              onInput={() => {}}
              onKeyDown={() => {}}
            />
          }
        />
      </div>

      <div className={styled.column}>
        <div className={styled.titleRow}>
          <h2 className={styled.sectionTitle}>
            <FormattedMessage {...structuresCreateMessages.structureType} />
          </h2>

          <Switch
            label1={intl.formatMessage(structuresCreateMessages.fixed)}
            label2={intl.formatMessage(structuresCreateMessages.tracker)}
            value={isTracker}
            onChange={onChangeStructureType}
          />
        </div>

        {isTracker ? (
          <>
            <InputRange
              label={intl.formatMessage(structuresCreateMessages.panelMaxTilt)}
              values={[formik.values.tracking_max_angle]}
              min={0}
              max={90}
              step={1}
              onChange={onChangeRange("tracking_max_angle")}
            />

            <CheckBox
              label={intl.formatMessage(structuresCreateMessages.backTracking)}
              checked={formik.values.backtracking}
              onChange={onChangeCheckBox("backtracking")}
            />
          </>
        ) : (
          <InputRange
            label={intl.formatMessage(structuresCreateMessages.panelTilt)}
            values={[formik.values.static_angle]}
            min={-90}
            max={90}
            step={1}
            onChange={onChangeRange("static_angle")}
          />
        )}

        <h2 className={clsx(styled.mt, styled.sectionTitle)}>
          <FormattedMessage {...structuresCreateMessages.samplePoints} />
        </h2>

        <table className={styled.table}>
          <thead>
            <tr>
              <th>Point Name</th>
              <th>Coordinates</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formik.values.points.map((item, i) => (
              <tr key={i}>
                <td>{item.name}</td>
                <td>
                  {item.x}, {item.y}
                </td>
                <td>
                  <div className={styled.row}>
                    <button
                      type="button"
                      className={styled.button}
                      onClick={onEditPoint(i)}
                    >
                      <Edit />
                    </button>
                    <button
                      type="button"
                      onClick={onPointDelete(i)}
                      className={clsx(styled.button, styled.deleteButton)}
                    >
                      <Delete />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Button
          iconBefore={<Plus />}
          onClick={onShowModal}
          variant="text"
          className={styled.addPoint}
        >
          <FormattedMessage {...structuresCreateMessages.addNewPoint} />
        </Button>

        <div className={styled.titleRow}>
          <h2 className={styled.sectionTitle}>
            <FormattedMessage
              {...structuresCreateMessages.agriPVZoneDefinition}
            />
          </h2>
        </div>

        <DottedLine
          item1={
            <Label>
              <div className={styled.flex}>
                <FormattedMessage
                  {...structuresCreateMessages.nonFarmingBandWidth}
                />
              </div>
            </Label>
          }
          item2={
            <Input
              type="number"
              {...getInputProps("no_crop_zone")}
              step={0.1}
            />
          }
        />

        <div className={styled.titleRow}>
          <h2 className={styled.sectionTitle}>
            <FormattedMessage
              {...structuresCreateMessages.groundCoverageRatio}
            />
          </h2>
        </div>

        <Input
          className={styled.panelHeightSelector}
          type="number"
          disabled={true}
          value={getGCR()}
        />
      </div>

      <div className={styled.columnRender}>
        <div className={styled.titleRow}>
          <h2 className={styled.sectionTitle}>
            <FormattedMessage {...structuresCreateMessages.visualDisplay} />
          </h2>

          <Button
            onClick={() => setDefaultPositionCanvas(true)}
            variant="text"
            className={styled.drawButton}
          >
            <FormattedMessage {...structuresCreateMessages.defaultPosition} />
          </Button>
        </div>

        <div className={styled.renderContainer} id="structure3D">
          {currentProject && (
            <Structure_3D
              panel_height={formik.values.panel_height}
              panel_opacity={formik.values.panel_opacity}
              panel_x={
                formik.values.structure_type == "fixed"
                  ? formik.values.panel_length
                  : formik.values.panel_width
              }
              panel_y={
                formik.values.structure_type == "fixed"
                  ? formik.values.panel_width
                  : formik.values.panel_length
              }
              initial_offset_x={formik.values.initial_offset_x}
              initial_offset_y={formik.values.initial_offset_y}
              panels_number_x={formik.values.panels_number_x}
              panels_number_y={formik.values.panels_number_y}
              panels_gap_x={
                formik.values.panels_gap_x +
                (formik.values.structure_type == "fixed"
                  ? formik.values.panel_length
                  : 0)
              }
              panels_gap_y={
                formik.values.panels_gap_y +
                (formik.values.structure_type == "tracker"
                  ? formik.values.panel_length
                  : 0)
              }
              field_size_x={formik.values.field_size_x}
              field_size_y={formik.values.field_size_y}
              azimuth={formik.values.azimuth}
              structure_type={formik.values.structure_type}
              static_angle={formik.values.static_angle}
              tracking_max_angle={formik.values.tracking_max_angle}
              backtracking={formik.values.backtracking}
              displacement_tracking={formik.values.displacement_tracking}
              solar_position={formik.values.solar_position}
              azimuth_solar={formik.values.azimuth_solar}
              zenith_solar={formik.values.zenith_solar}
              day={formik.values.day}
              hour={formik.values.hour}
              lat={currentProject.lat}
              long={currentProject.long}
              points={formik.values.points}
              defaultPositionBool={defaultPositionCanvas}
              setDefaultPositionBool={setDefaultPositionCanvas}
              no_crop_zone={formik.values.no_crop_zone}
            />
          )}
        </div>

        <div
          className={clsx(styled.mt, styled.titleRow)}
          style={{ display: "flex", gap: "1rem" }}
        >
          <Tooltip
            title={
              "This sun position is only used in the 3D visual display of the shade."
            }
          >
            <h2
              className={styled.sectionTitle}
              style={{ whiteSpace: "nowrap" }}
            >
              <FormattedMessage {...structuresCreateMessages.sunPosition} />
              <div className={styled["help-icon"]}>
                <Icon
                  name={ICON_NAMES_ENUM.help_circle}
                  className={styled.file__icon}
                />
              </div>
            </h2>
          </Tooltip>

          <Input
            disabled
            value={currentProject ? displayUTC(currentProject.utc) : ""}
            label={"TimeZone"}
          />

          {/* <Switch
            label1={intl.formatMessage(structuresCreateMessages.fixed)}
            label2={intl.formatMessage(structuresCreateMessages.date)}
            value={isDate}
            onChange={onChangeSolarPosition}
          /> */}
        </div>

        {!isDate ? (
          <>
            <InputRange
              label={intl.formatMessage(structuresCreateMessages.azimuth)}
              values={[formik.values.azimuth_solar]}
              min={-90}
              max={90}
              step={1}
              onChange={onChangeRange("azimuth_solar")}
            />

            <InputRange
              label={intl.formatMessage(structuresCreateMessages.zenith)}
              values={[formik.values.zenith_solar]}
              min={-90}
              max={90}
              step={1}
              onChange={onChangeRange("zenith_solar")}
            />
          </>
        ) : (
          <>
            <DottedLine
              item1={
                <Label htmlFor="day">
                  <FormattedMessage {...structuresCreateMessages.day} />
                </Label>
              }
              item2={
                <DatePicker
                  id="day"
                  date={date}
                  onChange={onChangeDate}
                  openDown={false}
                />
                // <div className={styled.datepicker}>
                //   <SingleDatePicker
                //     openDirection={"up"}
                //     customInputIcon={<Calendar />}
                //     renderMonthElement={({
                //       month,
                //       onMonthSelect,
                //       onYearSelect,
                //     }) => (
                //       <div
                //         style={{
                //           display: "flex",
                //           justifyContent: "center",
                //           gap: "1rem",
                //         }}
                //       >
                //         <div style={{ flex: 1 }}>
                //           <Select
                //             menuHeight={200}
                //             options={monthOptions}
                //             value={monthOptions.find(
                //               ({ value }) => value === month.month()
                //             )}
                //             onChange={(v: any) => onMonthSelect(month, v.value)}
                //           />
                //         </div>
                //         {/* <div style={{ flex: 1 }}>
                //         <Select
                //           menuHeight={200}
                //           options={yearOptions}
                //           value={yearOptions.find(
                //             ({ value }) => value === month.year()
                //           )}
                //           onChange={(v: any) => onYearSelect(month, v.value)}
                //         />
                //       </div> */}
                //       </div>
                //     )}
                //     navNext={<span />}
                //     navPrev={<span />}
                //     id="date"
                //     renderWeekHeaderElement={() => <span />}
                //     inputIconPosition="after"
                //     focused={datePickerFocus}
                //     date={date}
                //     onFocusChange={({ focused }) => setDatePickerFocus(focused)}
                //     onDateChange={(d) => onChangeDate(d)}
                //     numberOfMonths={1}
                //     displayFormat="DD MMM"
                //     // id={id}
                //     placeholder={"Date"}
                //     isOutsideRange={() => false}
                //   />
                // </div>
              }
            />

            <DottedLine
              item1={
                <Label htmlFor="hour">
                  <FormattedMessage {...structuresCreateMessages.hour} />
                </Label>
              }
              item2={
                <Input
                  type="number"
                  integerNumber
                  {...getInputProps("hour")}
                  step={1}
                />
              }
            />
          </>
        )}
      </div>

      <AddPointModal
        show={IsOpenModal}
        onHide={onHideModal}
        onSave={onSavePoint}
        {...(editPoint !== null && {
          initialValues: formik.values.points[editPoint],
        })}
      />
    </div>
  );
};

export default StructuresBody;
